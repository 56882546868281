import logo from './logo.svg';
import './App.css';
import W3Wallet from "./components/W3Wallet";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <W3Wallet />
      </header>
    </div>
  );
}

export default App;
