import {Contract, ethers} from "ethers";
import {useEffect, useMemo, Fragment, useState} from "react";
import dwtAbi from "./dwtAbi";

const InternalW3Wallet = () => {

    const contractId = '0x59b670e9fA9D0A427751Af201D676719a970857b';
    const provider = new ethers.providers.JsonRpcProvider('https://w3-rpc.handpickedlabs.nl');
    const [dwtBalance, setDwtBalance] = useState(0);
    const [isMemberOfSplit, setIsMemberOfSplit] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [numMembers, setNumMembers] = useState(0);

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
    }

    function getId() {
        const search = parseInt(window.location.search.replace('?', ''), 10);
        if (isNaN(search) || search === 0) {
            return getRndInteger(1, 29);
        }

        return search;
    }

    async function getSigner() {
        return provider.getSigner(getId());
    }

    function getContract(signerOrProvider) {
        return new Contract(contractId, dwtAbi, signerOrProvider);
    }

    async function joinSplit() {
        const signer = await getSigner();
        const contract = getContract(signer);

        try {
            await contract.joinSplit();
            await updateSplitMembers();
        } catch (e) {
            console.error(e);
        }
    }

    async function leaveSplit() {
        const signer = await getSigner();
        const contract = getContract(signer);

        try {
            await contract.leaveSplit();
            await updateSplitMembers();
        } catch (e) {
            console.error(e);
        }
    }

    async function updateSplitMembers() {

        const signer = await getSigner();
        const contract = getContract(signer);
        const address = await signer.getAddress();
        const splitMembers = await contract.getSplitMembers();

        let foundMember = false;
        let memberCount = 0;
        for (let member of splitMembers) {
            if (address === member) {
                foundMember = true;
            }

            if (member !== '0x0000000000000000000000000000000000000000') {
                memberCount++;
            }
        }

        setNumMembers(memberCount);
        setIsMemberOfSplit(foundMember);
    }

    async function updateBalance() {
        const signer = await getSigner();
        const contract = getContract(signer);
        const balance = await contract.balanceOf(signer.getAddress());
        setDwtBalance(balance);
    }

    async function updateWallet() {
        const signer = await getSigner();
        setWalletAddress(await signer.getAddress());
    }

    useEffect( () => {

        async function run() {
            // console.log(await provider.listAccounts())
            const signer = await getSigner();
            const contract = getContract(signer);

            contract.on('Transfer', updateBalance)
            contract.on('ParticipantUpdate', updateSplitMembers)

            updateWallet();
            updateBalance();
            updateSplitMembers();
        }

        run();
    }, [])



    return (
        <div>
            <div style={styles.walletId}>Wallet ID: {walletAddress}</div>
            <div style={styles.balance}>Balance: {dwtBalance && dwtBalance.toNumber().toFixed(2)}</div>
            <div>Number of people participating: {numMembers}</div>
            <div>
            {isMemberOfSplit && (
                <button onClick={leaveSplit} style={styles.leaveSplit}>Leave split</button>
            )}


            {!isMemberOfSplit && (
                <button onClick={joinSplit} style={styles.joinSplit}>Join split</button>
            )}
            </div>
        </div>
    )
}

const styles = {
    leaveSplit: {
        backgroundColor: 'red',
        padding: 10,
        fontWeight: 'bold',
        fontSize: '20px',
        marginTop: '1rem',
        border: '0',
        color: 'black'
    },

    joinSplit: {
        backgroundColor: 'green',
        padding: 10,
        fontWeight: 'bold',
        fontSize: '20px',
        marginTop: '1rem',
        border: '0',
        color: 'black'
    },

    walletId: {
        fontSize: '14px',
        marginBottom: '1rem'
    },

    balance: {
        marginBottom: '1rem'
    }
}


const W3Wallet = () => (
    useMemo(() => (
        <Fragment>
            <InternalW3Wallet />
        </Fragment>
    ), [])
);

export default W3Wallet;